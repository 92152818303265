import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-loading-skeleton/dist/skeleton.css";

import { api } from "../../utils/Api";
let myModal: any;
export default function ModalViewLocation(props: any) {
  console.log(props)
const lastDateRef = useRef<string>(null);

  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",

  });

  const [data,setData] = useState([])
const [visible_content,setVisibleContent] = useState(false)

 




  useEffect(() => {
    let myModal1: any = document.getElementById("view");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setData([]); // Reset data when modal closes
    lastDateRef.current = null; // Reset last date reference
    setVisibleContent(false); // Hide content
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log(props)
      getLocation({ start_time: props.start_time, id: props.id });
      myModal = new bootstrap.Modal(
        document.getElementById("view"),
        {}
      );
      myModal.show();
   
    }
  }, [props.open]);

  async function getLocation(get_data: any = {}) {
    console.log(get_data)


    const givenTime = get_data.start_time;
    const newTime = addMinutes(givenTime, 45);
    console.log(newTime)
    let pass_data = {
      post: {
        agent_id : get_data.id,
        start : get_data.start_time,
        end : newTime,

      }
    };
    let data_res = await api("/orders/get_dp_location", pass_data);

  console.log(data_res)
    if (data_res.status_code == 200) {

      if (data_res?.response?.results) {
        setData(prevData => {
          const newData = [...prevData, ...data_res.response.results];
          return newData;
        });
        
        // Set the last date time as the reference for next API call
        if (data_res.response.results.length > 0) {
          lastDateRef.current = data_res.response.results[data_res.response.results.length - 1].date_time;
        }
      
      }
 
      setVisibleContent(true);
    }
  }

// Function to add minutes to a date
function addMinutes(dateString, minutes) {
  // Parse the given time
  const [datePart, timePart, meridian] = dateString.split(" ");
  const [day, month, year] = datePart.split("-").map(Number);
  let [hours, minutesPart] = timePart.split(":").map(Number);

  // Adjust for AM/PM
  if (meridian === "PM" && hours !== 12) hours += 12;
  if (meridian === "AM" && hours === 12) hours = 0;

  // Create a Date object
  const date = new Date(year, month - 1, day, hours, minutesPart);

  // Add the specified minutes
  date.setMinutes(date.getMinutes() + minutes);

  // Format the output
  const newDay = String(date.getDate()).padStart(2, "0");
  const newMonth = String(date.getMonth() + 1).padStart(2, "0");
  const newYear = date.getFullYear();
  let newHours = date.getHours();
  const newMinutes = String(date.getMinutes()).padStart(2, "0");
  const newMeridian = newHours >= 12 ? "PM" : "AM";

  // Adjust hours back to 12-hour format
  if (newHours > 12) newHours -= 12;
  if (newHours === 0) newHours = 12;

  return `${newDay}-${newMonth}-${newYear} ${String(newHours).padStart(2, "0")}:${newMinutes} ${newMeridian}`;
}

 

  return (
    <div
      className="modal fade"
      id="view"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      style={{ zIndex: 9999 }} 
    >
    <div className="modal-dialog modal-lg modal-dialog-scrollable">
  <div className="modal-content seller-choose-modal">
    <div className="modal-header">
      <h6 className="modal-title" id="ModalLabel">Location</h6>
    </div>
    <div className="modal-body" id="ViewLocationBody">
      {props.open && (
        <div>
          {/* Header */}
          <div className="d-flex mb-2">
            <div className="fw-bold">Date & Time</div>
            <div className="fw-bold ms-auto">Count</div>
          </div>

          <InfiniteScroll
            dataLength={data.length}
           next={() => {
            console.log(lastDateRef.current)
                    if (lastDateRef.current < props.end_time) {
                      getLocation({ start_time: lastDateRef.current, id: props.id });
                    }
                  }
                }
            hasMore={true}
            loader={
              lastDateRef.current && lastDateRef.current < props.end_time ? (
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className="small ps-2">Just a second ...</p>
                </div>
              ) : null // Hide loader when end_time is reached
            }
            scrollableTarget="ViewLocationBody"
          >
            {data.map((item, index) => (
              <div key={index} className="d-flex">
                <div>{item.date_time}</div>
                <div className="ms-auto">{item.count}</div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Close
      </button>
    </div>
  </div>
</div>
    </div>
  );
}
