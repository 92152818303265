import React, { useState, useEffect, useContext } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import ModalStop from "./ModalStop";
import { AppConfig } from "../../config";
import ModalUserDetails from "../Orders/ModalUserDetails";

import toast, { Toaster } from "react-hot-toast";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
let map_api = AppConfig.api;
((g: any) => {
  var h: any,
    a: any,
    k: any,
    p = "The Google Maps JavaScript API",
    c: any = "google",
    l = "importLibrary",
    q = "__ib__",
    m: any = document,
    b: any = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r: any = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f: any, n: any) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t: any) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f: any, ...n: any) => r.add(f) && u().then(() => d[l](f, ...n)));
})({ key: map_api, v: "weekly" });
var lat: any, long: any;

let map: any;
let timeOutId :any;

var bounds: any;
let marker: any = {};
async function initMap(): Promise<void> {
  const { Map }: any = await google.maps.importLibrary("maps");

  map = new Map(document.getElementById("map-test") as HTMLElement, {
    zoom: 12,
    center: { lat: 11.341, lng: 77.7172 },
    // center: { lat: 26.7164127, lng: 88.4309916 },

    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    mapTypeId: "roadmap",
    gestureHandling: "greedy",

    mapId: "DEMO_MAP_ID",
  });

  bounds = new google.maps.LatLngBounds();

  map.addListener("center_changed", () => {
    lat = map.getCenter()?.lat();
    long = map.getCenter()?.lng();
  });
  // get_map_details();
}

function openMap(dir_lat: any, dir_lng: any) {
  // window.open(
  //  "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
  // "_blank"
  // );

  document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
  "_blank"
}

const responsive = {
  0: { items: 2 },
  200: { items: 3 },
  300: { items: 4 },
  350: { items: 5 },
  568: { items: 10 },
  1024: { items: 12 },
};

// menu_click
const initial_value = {};

export default function Map(props: any) {
  const context: any = useContext(DataContext);
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [show_marker_data, SetShowMarkerData] = useState({
    is_open: false,
    view_type: null,
    company_data: {},
    delivery_person_data: {},
    delivery_person_full_data: {},
    page_no: page_no,
    next_page: false,
  });
  const { SetOutletContextData } = useOutletContext();
  const [all_data, SetAllData]: any = useState({});
  const [dp_fiter_data, SetDPFilterData]: any = useState([]);
  const [whole_dp_fiter_data, SetWholeDPFilterData]: any = useState([]);
  const [address, setAddress] = useState('');
  const [session_data, SetSessionData]: any = useState([]);
  const [session_data_details, SetSessionDataDetails]: any = useState({
    agent_id: "",
    company_id: "",
    all: "",
    page: page_no,
  });
  const [selected_tab, SetSelectedTab]: any = useState({
    id: 1,
    name: "Dashboard",
  });
  const [tab_data, SetTabData]: any = useState([
    { id: 1, name: "Dashboard" },
    { id: 2, name: "Live" },
    { id: 3, name: "Session" },
    { id: 4, name: "Profile" },
    { id: 5, name: "Services" },

  ]);
  const [main_tab_data, SetMainTabData] = useState([
    { id: 1, name: "Statistics" },
    { id: 2, name: "Delivery Person" },
  ]);
  const [selected_main_tab, SetSelectedMainTab]: any = useState({
    id: 1,
    name: "Statistics",
  });
  const [bottom_sheet_open, SetBottomSheetOpen] = useState(false);
  const [width, SetWidth] = useState(window.innerWidth);
  const [live_tab_data, SetLiveTabdata] = useState({
    live_page_no: 1,
    live_next_page: false,
    is_live_api_loading: true,
    reservation_page_no: 1,
    reservation_next_page: false,
    is_reservation_api_loading: true,
  });
  const [live_order_data, SetLiveOrderData] = useState([]);
  const [live_reservation_data, SetLiveReservationData] = useState([]);

  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    data: {},
  });
  const [user_modal, SetUserModal] = useState({
    is_open: false,
   id:""
  });
  const [show_map_details_loader, SetMapDetailsLoader] = useState(true);
  const [session_loading, SetSessionLoading] = useState(true);
  let intervalId:any;

  useEffect(() => {
    SetOutletContextData((prevValue) => {
      prevValue.have_menu = false;
      return { ...prevValue };
    });
    
    const initialize = async () => {
      await initMap();
      await get_map_details();
    };
  
    initialize();

    return () => {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
      if(intervalId){
        clearInterval(intervalId);
      }
    };
  }, []);
 
  useEffect(() => {
    if (bottom_sheet_open) {
      context.SetAppData((prevValue: any) => {
        prevValue.scroll_locked++;
        return { ...prevValue };
      });
    } else {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
    }
  }, [bottom_sheet_open]);

  const handleCall = (e,fullMobile) => {
    e.stopPropagation()
    if (fullMobile) {
      window.location.href = `tel:${fullMobile}`;
    }
  };

  const handleEmail = (e,email) => {
    e.stopPropagation()
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };


  async function get_map_details() {
    // SetMapDetailsLoader(true);
    let response: any = await api("/orders/get_delivery_company_partners");
    if (response.status_code == 200) {
      SetMapDetailsLoader(false);
      if (response.response.hasOwnProperty("data")) {
        SetAllData(response.response.data[0]);
        SetDPFilterData(response.response.data[0].delivery_partners);
   
        intervalId = setInterval(function () {
          get_current_location();
        }, 10000);

  SetWholeDPFilterData(response.response.data[0].delivery_partners);
        SetShowMarkerData((prevalue: any) => {
          prevalue.view_type = "main";
          return { ...prevalue };
        });
        response.response.data.map((ord_ele: any, ord_index: any) => {
          ord_ele.delivery_partners.map(async (ele: any, ord_index: any) => {
            marker[ele.agent_id] = {
              marker: {},
              pass_data: {},
            };
            if (Object.keys(ele.location).length > 0 && ele.logged_in == true) {
              let ele_data: any = {};
              let img_url = "";
              if (ele.marker == "blue") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-blue.png";
              }
              if (ele.marker == "green") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-green.png";
              }
              if (ele.marker == "orange") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-orange.png";
              }
              if (ele.marker == "red") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-red.png";
              }
              if (ele.marker == "yellow") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-yellow.png";
              }
              if (ele.marker == "") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-orange.png";
              }
              let added_url = img_url + "?agent_id=" + ele.agent_id;
              marker[ele.agent_id]["marker"] = new google.maps.Marker({
                position: new google.maps.LatLng(
                  ele.location.lat,
                  ele.location.lng
                ),
                map,
                icon: {
                  url: added_url,
                  scaledSize: new google.maps.Size(23, 35),
                },
                anchorPoint: new google.maps.Point(0, -29),
              });
              let pass_data = {
                company_data: ord_ele,
                delivery_person_data: ele,
                added_url: added_url,
              };
              marker[ele.agent_id].pass_data = pass_data;
              AttachMarkerData(marker, pass_data);
            }
          });
        });
      }
    }
  }

  function AttachMarkerData(marker: any, get_data: any) {
    let doc_var: any = document.querySelector(
      'img[src="' + get_data.added_url + '"]'
    );
    if (doc_var != null) {
      doc_var.style.transform =
        "rotate(" + get_data.delivery_person_data.location.heading + "deg)";
      doc_var.parentElement.style.overflow = "inherit";
    }

    marker[get_data.delivery_person_data.agent_id].marker.addListener(
      "click",
      (ele: any) => {
    
        Object.keys(marker).map((key) => {
          if (key == get_data.delivery_person_data.agent_id) {
            let doc_var: any = document.querySelector(
              'img[src="' + get_data.added_url + '"]'
            );
            if (doc_var != null) {
              doc_var.style.transform =
                "rotate(" +
                get_data.delivery_person_data.location.heading +
                "deg) scale(2)";
              doc_var.parentElement.style.overflow = "inherit";
              // doc_var.style.transform = "scale(2)";
              doc_var.style.transition = "scale 300ms";
            }
          } else {
            let doc_var: any = document.querySelector(
              'img[src="' + marker[key].pass_data.added_url + '"]'
            );
            if (doc_var != null) {
              doc_var.style.transform =
                "rotate(" +
                marker[key].pass_data.delivery_person_data.location.heading +
                "deg) scale(1)";
              doc_var.parentElement.style.overflow = "inherit";
            }
          }
        });
        SetShowMarkerData((preValue: any) => {
          preValue.is_open = true;
          preValue.view_type = "personal_details";
          preValue.loading = true;
          //  preValue.company_data = get_data.company_data;
          //  preValue.delivery_person_data = get_data.delivery_person_data;
          //  preValue.delivery_person_full_data = response.response.data[0];
          let send_data = {
            company_data: get_data.company_data,
            delivery_person_data: get_data.delivery_person_data,
          };
          get_person_details(send_data);

          let send_data1 = {
            company_data: get_data.company_data,
            delivery_person_data: get_data.delivery_person_data,
            page_no: page_no,
            fetch_more: false,
          };
          SetSessionDataDetails({
            agent_id: get_data.delivery_person_data,
            company_id: get_data.company_data,
            all: get_data.delivery_person_data,
            page: page_no,
          });
          get_person_sessions(send_data1);
          return { ...preValue };
        });
        SetSelectedTab({
          id: 1,
          name: "Dashboard",
        });
        if (width <= 768) {
          SetBottomSheetOpen(true);
        }
      }
    );
  }

  async function get_person_details(get_data: any) {
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
      },
    };
    let response: any = await api(
      "/orders/get_delivery_partners_details",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (
        response.response.hasOwnProperty("data") &&
        response.response.data.length != 0
      ) {

        // getAddress(get_data.delivery_person_data.location);
        SetShowMarkerData((preValue: any) => {
          preValue.is_open = true;
          preValue.view_type = "personal_details";
          preValue.loading = false;
          preValue.company_data = get_data.company_data;
          preValue.delivery_person_data = get_data.delivery_person_data;
          preValue.delivery_person_full_data = response.response.data[0];
          return { ...preValue };
        });

        console.log(get_data.delivery_person_data.location)
      }
    } else {
      // SetBottomSheetOpen(false);
      toast.success("no");
      // success("no data")
    }
  }


  async function get_person_sessions(get_data: any) {
    console.log(get_data)
    if (!get_data.fetch_more) {
      SetSessionLoading(true);
    }
    SetSessionDataDetails({
      agent_id: get_data.delivery_person_data,
      company_id: get_data.delivery_person_data,
      all: get_data.delivery_person_data,
      page: page_no,
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        company_id: get_data.delivery_person_data.id,
        all: "1",
        page: 1,
      },
    };
    let response: any = await api(
      "/orders/get_dp_working_sessions",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      SetSessionLoading(false);
      if (
        response.response.hasOwnProperty("data") &&
        response.response.data.length > 0
      ) {
        SetSessionData((prevalue: any) => {
          if (get_data.fetch_more) {
            response.response.data.map((ele: any) => {
              prevalue.push(ele);
            });
          } else {
            prevalue = response.response.data;
          }
          return [...prevalue];
        });
        SetNextPage(response.response.next_page);
      } else {
        SetSessionData([]);
        SetNextPage(false);
      }
      SetShowMarkerData((preValue: any) => {
        let old_value = preValue.page_no;
        if (response.response.next_page) {
          old_value = old_value + 1;
        }
        preValue.page_no = old_value;
        preValue.next_page = response.response.next_page;
        return { ...preValue };
      });
    }
  }

  async function load_more() {
    let page_no_value = page_no + 1;
    let pass_data = {};
console.log(session_data_details)
    SetPageNo(page_no_value);

    pass_data = {
      get: {
        agent_id: session_data_details.agent_id.agent_id,
        company_id: session_data_details.company_id.id,
        all: "1",
        page: page_no_value,
      },
    };

    let response: any = await api(
      "/orders/get_dp_working_sessions",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    SetSessionData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  async function get_current_location() {
    let array_id: any = [];

    SetAllData((prevalue: any) => {
      prevalue?.delivery_partners?.map((ele: any) => {
        if (ele.logged_in) {
          array_id.push(ele.agent_id);
        }
      });
      return { ...prevalue };
    });
    const arrString = JSON.stringify(array_id);
    let pass_data = {
      post: {
        agent_ids: arrString,
      },
    };
    let response: any = await api(
      "/orders/get_delivery_partners_location",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        response.response.data.map((ele: any, ord_index: any) => {
          marker[ele.agent_id].pass_data.delivery_person_data.location =
            ele.location;
          SetShowMarkerData((preValue: any) => {
            if (preValue.view_type == "personal_details") {
              Object.keys(marker).map((key) => {
                if (key == ele.agent_id) {
                  let myLatlng = new google.maps.LatLng(
                    ele.location.lat,
                    ele.location.lng
                  );
                  marker[key].marker.setPosition(myLatlng);
                  let doc_var: any = document.querySelector(
                    'img[src="' + marker[key].pass_data.added_url + '"]'
                  );
                  if (doc_var != null) {
                    if (preValue.delivery_person_data.agent_id == key) {
                      // doc_var.style.transform = "scale(2)";
                      doc_var.style.transform =
                        "rotate(" + ele.location.heading + "deg scale(2))";
                      doc_var.style.transition = "scale 300ms";
                    } else {
                      doc_var.style.transform =
                        "rotate(" + ele.location.heading + "deg)";
                      doc_var.parentElement.style.overflow = "inherit";
                    }
                  }
                }
              });
              
            } else {
              Object.keys(marker).map((key) => {
                if (key == ele.agent_id) {
                  let myLatlng = new google.maps.LatLng(
                    ele.location.lat,
                    ele.location.lng
                  );
                  marker[key].marker.setPosition(myLatlng);
                  let doc_var: any = document.querySelector(
                    'img[src="' + marker[key].pass_data.added_url + '"]'
                  );
                  if (doc_var != null) {
                    doc_var.style.transform =
                      "rotate(" + ele.location.heading + "deg)";
                    doc_var.parentElement.style.overflow = "inherit";
                  }
                }
              });
            }
            return { ...preValue };
          });
        });
      }
    }
  }

  function get_session_details(ele: any) {
    return (
      <div className="sm-px-1">
        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
          {ele.working ? (
            <div>
              <div className="d-flex align-items-center pb-3">
                <div className="color-dot-red"></div>
                <p className="ps-2 text-red">Live</p>
              </div>
              <div className="d-flex align-items-center pb-1">
                <p className="text-black">
                  {ele.start_time_data.date}/{ele.start_time_data.month_short}/
                  {ele.start_time_data.year}
                </p>
                <div className="ms-auto">
                  <p className="text-black">{ele.start_time_data.time}</p>
                </div>
              </div>
              <p className="text-black">{ele.start_time_data.day_full}</p>
              <div className="mt-3">
                <p className="extra-small pb-1">Total Session</p>
                <p className="text-black">{ele.duration}</p>
              </div>
            </div>
          ) : (
            <div className="p-1">
              <div className="row">
                <div className="col-6 border-right">
                  <span className="extra-small pb-1 text-gray">Start</span>
                  <div className="d-flex align-items-center py-1">
                    <p className=" text-black">
                      {ele.start_time_data.date}/
                      {ele.start_time_data.month_short}/
                      {ele.start_time_data.year}
                    </p>
                    <div className="ms-auto">
                      <p className="ps-2 text-black">
                        {ele.start_time_data.time}
                      </p>
                    </div>
                  </div>
                  <p className="pb-2 text-black">
                    {ele.start_time_data.day_full}
                  </p>
                </div>
                <div className="col-6">
                  <span className="extra-small pb-1 text-gray">End</span>
                  <div className="d-flex align-items-center py-1">
                    <p className=" text-black">
                      {ele.end_time_data.date}/{ele.end_time_data.month_short}/
                      {ele.end_time_data.year}
                    </p>
                    <div className="ms-auto">
                      <p className="ps-2 text-black">
                        {ele.end_time_data.time}
                      </p>
                    </div>
                  </div>
                  <p className="pb-2 text-black">
                    {ele.end_time_data.day_full}
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <p className=" extra-small pb-1">Total Session</p>
                <p className=" text-black">{ele.duration}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  async function get_live_orders(get_data: any) {
    SetLiveTabdata((prevalue) => {
      prevalue.is_live_api_loading = true;
      return prevalue;
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        page: get_data.page_no,
        view: "active",
      },
    };
    let response: any = await api(
      "/orders/get_dp_orders",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (get_data.fetch_more) {
        SetLiveOrderData((prevalue) => {
          response.response.data.map((ele) => {
            prevalue.push(ele);
          });
          return [...prevalue];
        });
      } else {
        SetLiveOrderData(response.response.data);
      }
      SetLiveTabdata((prevalue) => {
        prevalue.is_live_api_loading = false;
        if (response.response.next_page) {
          prevalue.live_page_no = prevalue.live_page_no + 1;
        }
        prevalue.live_next_page = response.response.next_page;
        return prevalue;
      });
    }
  }

  async function get_live_orders_reservation(get_data: any) {
    SetLiveTabdata((prevalue) => {
      prevalue.is_reservation_api_loading = true;
      return prevalue;
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        page: get_data.page_no,
        view: "active",
      },
    };
    let response: any = await api(
      "/orders/get_dp_order_reservations",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (get_data.fetch_more) {
        SetLiveReservationData((prevalue) => {
          response.response.data.map((ele: any) => {
            prevalue.push(ele);
          });
          return [...prevalue];
        });
      } else {
        SetLiveReservationData(response.response.data);
      }
      SetLiveTabdata((prevalue) => {
        prevalue.is_reservation_api_loading = false;
        if (response.response.next_page) {
          prevalue.reservation_page_no = prevalue.reservation_page_no + 1;
        }
        prevalue.reservation_next_page = response.response.next_page;
        return prevalue;
      });
    }
  }

  const TimelineAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        <div
          className="d-flex align-items-center pt-2 cursor"
          onClick={() => {
            // SetTimelineAccrData((prevalue)=>{
            //   prevalue.status_timeline = !prevalue.status_timeline
            //   return {...prevalue}
            // })
            SetIsopen(!is_open);
          }}
        >
          <p className="text-dark fw-bold">TIMELINE</p>
          <div className="ms-auto ">
            {is_open ? (
              <MaterialIcons
                name="keyboard-arrow-down"
                size={18}
                color="black"
              />
            ) : (
              <MaterialIcons name="keyboard-arrow-up" size={18} color="black" />
            )}
          </div>
        </div>
        <div className="">{is_open ? acc_props.children : null}</div>
      </>
    );
  };

  const PersonDetailsPanel = () => {
    return (
      <>
        {show_marker_data.loading ? (
          <div>
            <div className="card px-3 pb-3 pt-2 position-sticky-top">
              <div className="row">
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
              </div>
              <div className="pt-3">
                <div className="d-flex align-items-center py-2 w-100">
                  <div>
                    <Skeleton height={10} width={80} />
                    <Skeleton height={10} width={180} />
                  </div>

                  <div className=" ms-auto">
                    <Skeleton height={10} width={80} />
                  </div>
                </div>
              </div>
            </div>

            {selected_tab.name == "Dashboard" ? (
              <div>
                <div className="py-3 row sm-px-1">
                  <div className="col-6 sm-pe-1">
                    <div className="card p-3 sm-px-1 sm-py-2">
                      <div className="h-35">
                        <Skeleton height={10} width={180} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>

                  <div className="col-6 sm-pe-1">
                    <div className="card p-3 sm-px-1 sm-py-2">
                      <div className="h-35">
                        <Skeleton height={10} width={180} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                </div>

                <div className="card p-3 sm-px-1 sm-py-2 mb-3">
                  <div className="row p-2">
                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>

                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>

                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                </div>

                <div className="sm-px-1">
                  <div className="card p-3 sm-px-1">
                    <div className="row p-2">
                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>
                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <div className="">
              <div className="card px-3 pb-3 pt-2 position-sticky-top">
                <AliceCarousel
                  mouseTracking
                  touchMoveDefaultEvents={false}
                  // paddingRight={10}
                  disableDotsControls
                  responsive={responsive}
                  disableButtonsControls
                  controlsStrategy="alternate"
                >
                  {tab_data?.map((tab: any, index: any) => (
                    <div
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      key={index}
                    >
                      <div
                        className="text-center"
                        onClick={() => {
                          SetSelectedTab(tab);
                          if (tab.id == "2") {
                            SetShowMarkerData((prevalue) => {
                              let send_data: any = prevalue;
                              let send_data1: any = prevalue;
                              SetLiveTabdata((live_prevalue: any) => {
                                send_data["fetch_more"] = false;
                                send_data["page_no"] =
                                  live_prevalue.live_page_no;
                                send_data1["fetch_more"] = false;
                                send_data1["page_no"] =
                                  live_prevalue.reservation_page_no;
                                get_live_orders(send_data);
                                get_live_orders_reservation(send_data1);
                                return { ...live_prevalue };
                              });
                              return { ...prevalue };
                            });
                          }
                        }}
                      >
                        <div
                          className={
                            selected_tab.id == tab.id
                              ? "tab-active p-2 pt-3 cursor sm-px-0"
                              : "cursor p-2 pt-3 sm-px-0 border-bottom"
                          }
                        >
                          <div>
                            <p
                              className={
                                selected_tab.id == tab.id
                                  ? "py-1 text-prime"
                                  : "py-1 text-black"
                              }
                            >
                              {tab.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </AliceCarousel>
                {/* top */}
                <div className="pt-3">
                  <div className="d-flex align-items-center py-2 w-100 row">
                    <div className="col-8">
                    <p className=" text-black">
                      ID: {show_marker_data.delivery_person_full_data.id}
                    </p>
                    <p className="text-dark fw-bold">
                    {
                      show_marker_data.delivery_person_full_data.user_details
                        .name
                    }
                  </p>
                    </div>
                    <div className="col-4">
                    
                    <div className=" ms-auto">
                      {show_marker_data.delivery_person_full_data
                        .new_reservation_blocked ? (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-yellow"></div>
                          <p className="ps-2 text-orange">Blocked</p>
                        </div>
                      ) : show_marker_data.delivery_person_full_data
                          .logged_in ? (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-green"></div>
                          <p className="ps-2 text-green">Active</p>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-red"></div>
                          <p className="ps-2 text-black">InActive</p>
                        </div>
                      )}
<p>{show_marker_data.delivery_person_data.location.time}</p>
                     
                    </div>
                  </div>
                  </div>

            
                </div>
              </div>

              {selected_tab.name == "Dashboard" ? (
                <div>
         
                   <div className="col-12 sm-pe-1 mt-3">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Latest Location
                        </p>
                        <div className="d-flex">
                        <p className="text-black">
                          {" "}
                          {show_marker_data.delivery_person_data.location.time}
                        </p>
                        <div className="ms-auto">
                              {/* <button
                                className="btn btn-prime btn-small "
                            
                              >  */}
                               {/* <span className="small">Map</span> */}
                                 <img
                                
                                     onClick={() => {
                                      openMap(
                                        show_marker_data.delivery_person_data.location.lat,
                                        show_marker_data.delivery_person_data.location.lng
                                      );
                                    }}
                                      src={require("../../assets/icon/map-arrow.png")}
                                      className="icon-24px"
                                    />
                              {/* </button> */}
                            </div>
                      </div>

                      </div>
                    </div>
                  
                  <div className="py-3 row sm-px-1">
              
                    <div className="col-6 sm-pe-1">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Total Ongoing Orders
                        </p>
                        <p className="text-black">
                          {" "}
                          {
                            show_marker_data.delivery_person_full_data
                              .total_ongoing_orders
                          }
                        </p>
                      </div>
                    </div>

                    <div className="col-6 sm-ps-1">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Total Ongoing Reservations
                        </p>
                        <p className="text-black">
                          {
                            show_marker_data.delivery_person_full_data
                              .total_ongoing_reservations
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 sm-px-1 sm-py-2 mb-3">
                    <div className="row p-2">
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Balance Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {show_marker_data.delivery_person_data.balance_amount}
                        </p>
                      </div>
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Threshold Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {
                            show_marker_data.delivery_person_data
                              .threshold_amount
                          }
                        </p>
                      </div>
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Deposited Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {
                            show_marker_data.delivery_person_data
                              .deposited_amount
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm-px-1">
                    <div className="card p-3 sm-px-1">
                      <div className="row p-2">
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Assigned Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_assigned_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Cancelled Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_cancelled_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Finished Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_finished_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Rejected Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_rejected_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black ">
                            Total Expired Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_expired_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Assigned Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_assigned_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Cancelled Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_cancelled_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Order Converted Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_order_converted_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Forwarded Others Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_forwarded_others_reservations
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {selected_tab.name == "Live" ? (
                <div>
                  <div className="my-2">
                    <div className="bg-fff shadow p-3 radius-none sm-px-1 sm-py-2">
                      <h6 className="mb-0">Live Orders</h6>
                    </div>

                    {!live_tab_data.is_live_api_loading ? (
                      live_order_data.length > 0 ? (
                        <>
                          {live_order_data.map((ele: any, index) => (
                            <div
                              className={
                                index == 0
                                  ? "radius-top-none card p-3 mb-2 sm-px-1 sm-py-2"
                                  : "card p-3 mb-2 sm-px-1 sm-py-2"
                              }
                              key={index}
                            >
                              <div className="">
                                <div className="d-flex align-items-center pb-3">
                                  <div className="color-dot-red"></div>
                                  <p className="ps-2 text-red">Live</p>

                                  <div className="ms-auto">
                                    <Link
                                      to={"/orders/" + ele.id}
                                      target="_blank"
                                    >
                                      <button className="btn btn-sm btn-prime">
                                        Go to Order Details
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center pb-1">
                                  <p className=" text-dark"># {ele.id}</p>
                                  <div className="ms-auto">
                                    <p className=" text-gray small">
                                      {ele.time}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <p className=" text-black">Customer</p> */}
                              <div className="row py-3">
                                <div className="col-4 border-right sm-border-right-none pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/profile.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray sm-fs-9">
                                        Customer Name
                                      </p>
                                      <p className=" sm-fs-10 text-dark fw-bold">
                                        {ele.customer.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4 border-right sm-border-right-none pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/Delivery.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray sm-fs-9">
                                        Order Type
                                      </p>
                                      <p className=" sm-fs-10 text-dark fw-bold">
                                        {ele.sector_id == 4
                                          ? "Pickupdrop"
                                          : ele.sector_id == 2
                                          ? "Resturant"
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4  pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/indian-rupee.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray  sm-fs-9">
                                        Total Amount
                                      </p>
                                      <p className=" text-dark fw-bold  sm-fs-10">
                                        ₹ {ele.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {ele.sector_id == 4 ? (
                                <div className="pb-2">
                                  <div className=" pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Pickup Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.pickup_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {ele.sector_id == 2 ? (
                                <div className=" pb-2">
                                  <div className="pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Store Address
                                      </p>

                                      <p className="pt-1">
                                        <p>{ele.seller.outlet.map_address}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>

                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="border-top">
                                <TimelineAccordian>
                                  <div className="">
                                    <ul className="delivery_status_vertical w-100">
                                      {ele.delivery_partner_order?.status_timeline?.map(
                                        (ele: any) => (
                                          <li>
                                            <time className="extra-small w-30 text-gray">
                                              {ele?.time_formatted}
                                            </time>
                                            <div className="timeline w-70">
                                              <span className="text-dark ">
                                                {ele?.status}
                                              </span>
                                              {ele?.user.profile != "" && (
                                                <div className="d-flex align-items-center pt-2">
                                                  {ele?.user.profile ? (
                                                    <img
                                                      src={ele?.user.profile}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={require("../../assets/img/profile.png")}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  )}
                                                  <p className=" text-gray">
                                                    {ele?.user.name}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </TimelineAccordian>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="card radius-top-none p-3">
                          <p>No Live Data</p>
                        </div>
                      )
                    ) : (
                      <div>
                        <div className="card p-3 mb-2 sm-px-1 sm-py-2">
                          <div className="">
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={30} width={100} />
                              </div>
                            </div>

                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={50} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="pb-2">
                              <div className=" pt-2 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>

                              <div className=" pt-3 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="border-top">
                              <div className="pt-2">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-2 align-center">
                      {live_tab_data.live_next_page ? (
                        <button
                          className="btn btn-text text-prime w-100 bg-fff radius shadow fw-bold py-2"
                          onClick={() => {
                            SetShowMarkerData((prevalue) => {
                              let send_data: any = prevalue;
                              SetLiveTabdata((live_prevalue: any) => {
                                send_data["fetch_more"] = true;
                                send_data["page_no"] =
                                  live_prevalue.live_page_no;
                                get_live_orders(send_data);
                                return { ...live_prevalue };
                              });
                              return { ...prevalue };
                            });
                          }}
                        >
                          Load more
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="my-2">
                    <div className="bg-fff shadow p-3 radius-none sm-px-1 sm-py-2">
                      <h6>Reservation Orders </h6>
                    </div>

                    {!live_tab_data.is_reservation_api_loading ? (
                      live_reservation_data.length > 0 ? (
                        <>
                          {live_reservation_data.map((ele: any, index) => (
                            <div
                              className={
                                index == 0
                                  ? "radius-top-none card p-3 mb-2 sm-px-1 sm-py-2"
                                  : "card p-3 mb-2 sm-px-1 sm-py-2"
                              }
                              key={index}
                            >
                              {/* <Link to={"/orders/" + ele.id} target="_blank"> */}
                              <div className="d-flex align-items-center pb-3">
                                <div className="color-dot-red"></div>
                                <p className="ps-2 text-red">Live</p>
                              </div>
                              <div className="d-flex align-items-center pb-1">
                                <p className=" text-dark"># {ele.id}</p>
                                <div className="ms-auto">
                                  <p className=" text-gray small">{ele.time}</p>
                                </div>
                              </div>
                              <div className="row py-3">
                                <div className="col-md-4 col-6 border-right sm-border-right-none">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/profile.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Customer Name
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        {ele.customer.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 border-right sm-border-right-none">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/Delivery.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Order Type
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        {ele.sector_id == 4
                                          ? "Pickupdrop"
                                          : ele.sector_id == 2
                                          ? "Resturant"
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 ">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/indian-rupee.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Total Amount
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        ₹ {ele.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {ele.sector_id == 4 ? (
                                <div className="pb-2">
                                  <div className=" pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Pickup Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.pickup_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {ele.sector_id == 2 ? (
                                <div className=" pb-2">
                                  <div className="pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Store Address
                                      </p>

                                      <p className="pt-1">
                                        <p>{ele.seller.outlet.map_address}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      {/* <p>
                                            {
                                              ele.customer.delivery_address
                                                .map_address
                                            }
                                          </p> */}
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="border-top">
                                <TimelineAccordian>
                                  <div className="">
                                    <ul className="delivery_status_vertical w-100">
                                      {ele.delivery_partner_order?.status_timeline?.map(
                                        (ele: any) => (
                                          <li>
                                            <time className="extra-small w-30 text-gray">
                                              {ele?.time_formatted}
                                            </time>
                                            <div className="timeline w-70">
                                              <span className="text-dark">
                                                : {ele?.status}
                                              </span>
                                              {ele?.user.profile != "" && (
                                                <div className="d-flex align-items-center pt-2">
                                                  {ele?.user.profile ? (
                                                    <img
                                                      src={ele?.user.profile}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={require("../../assets/img/profile.png")}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  )}

                                                  <p className=" text-gray">
                                                    {ele?.user.name}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </TimelineAccordian>
                              </div>
                              {/* </Link> */}
                            </div>
                          ))}
                          <div className="mt-2 align-center">
                            {live_tab_data.reservation_next_page ? (
                              <button
                                className="btn btn-text text-prime w-100 bg-fff radius shadow fw-bold py-2"
                                onClick={() => {
                                  SetShowMarkerData((prevalue) => {
                                    let send_data1: any = prevalue;
                                    SetLiveTabdata((live_prevalue: any) => {
                                      send_data1["fetch_more"] = true;
                                      send_data1["page_no"] =
                                        live_prevalue.reservation_page_no;
                                      get_live_orders_reservation(send_data1);
                                      return { ...live_prevalue };
                                    });
                                    return { ...prevalue };
                                  });
                                }}
                              >
                                Load more
                              </button>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <div className="card radius-top-none px-3 pb-3">
                          <p>No Reservation Data</p>
                        </div>
                      )
                    ) : (
                      <div>
                        <div className="card p-3 mb-2 sm-px-1 sm-py-2">
                          <div className="">
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={30} width={100} />
                              </div>
                            </div>

                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={50} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="pb-2">
                              <div className=" pt-2 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>

                              <div className=" pt-3 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="border-top">
                              <div className="pt-2">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {selected_tab.name == "Session" ? (
                <div className="py-3">
                  {session_loading ? (
                    <div>
                      <div className="sm-px-1">
                        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
                          <div>
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={50} />
                            </div>
                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={50} />
                              </div>
                            </div>
                            <Skeleton height={10} width={50} />
                            <div className="mt-2">
                              <div className="pb-1">
                                <Skeleton height={10} width={80} />
                              </div>
                              <Skeleton height={10} width={120} />
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
                          <div className="p-1">
                            <div className="row">
                              <div className="col-6 border-right">
                                <div className="pb-1">
                                  <Skeleton height={8} width={30} />
                                </div>
                                <div className="d-flex align-items-center py-1">
                                  <Skeleton height={10} width={80} />
                                  <div className="ms-auto">
                                    <div className="ps-2">
                                      <Skeleton height={10} width={50} />
                                    </div>
                                  </div>
                                </div>
                                <div className="pb-2">
                                  <Skeleton height={10} width={50} />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="pb-1">
                                  <Skeleton height={8} width={30} />
                                </div>
                                <div className="d-flex align-items-center py-1">
                                  <Skeleton height={10} width={80} />
                                  <div className="ms-auto">
                                    <div className="ps-2">
                                      <Skeleton height={10} width={50} />
                                    </div>
                                  </div>
                                </div>
                                <div className="pb-2">
                                  <Skeleton height={10} width={50} />
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="pb-1">
                                <Skeleton height={10} width={80} />
                              </div>
                              <Skeleton height={10} width={120} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {session_data.length > 0 ? (
                        session_data.map((ele: any) => get_session_details(ele))
                      ) : (
                        <div className=" sm-px-1">
                          <div className="card p-3 text-center">
                            <p>No Session Details</p>
                          </div>
                        </div>
                      )}

                      {next_page ? (
                        <div className=" sm-px-1">
                          <div className="my-3 card p-3 w-100">
                            <p
                              className="text-prime fw-bold text-center cursor"
                              onClick={() => {
                                load_more();
                              }}
                            >
                              Load More
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}
              {selected_tab.name == "Profile" ? (
                <div>
                  {show_marker_data.loading ? (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                        <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <Skeleton
                              height={70}
                              width={70}
                              borderRadius={50}
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <Skeleton height={8} width={50} />
                            <div className="pt-2">
                              <Skeleton height={10} width={85} />
                            </div>
                          </div>
                        </div>
                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={50} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />

                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                      <div className="d-flex border-bottom pb-2 mb-2">

                      
    {show_marker_data.delivery_person_full_data?.user_details?.profile ? (
      <img
        src={show_marker_data.delivery_person_full_data.user_details.profile}
        alt="Profile"
        className="profile-round-img"
        height="34"
        width="34"
        style={{ borderRadius: "50%" }}
      />
    ) : (
      <img
        src={require("../../assets/icon/avatar.png")}
        className="profile-round-img"
        height="34"
        width="34"
        style={{ borderRadius: "50%" }}
      />
    )}
              <div className="ms-2">


    <p className="text-dark fw-bold fs-7">{show_marker_data.delivery_person_full_data?.user_details?.name}</p>
    <p>{show_marker_data.delivery_person_full_data?.user_details?.username}</p>
    </div>
      <div className="ms-auto text-end">
                           
                      
                            <div className="d-flex end">
                              <div className="call-btn m-2" onClick={(e) => handleCall(e, show_marker_data.delivery_person_full_data?.user_details.full_mobile)} style={{ cursor: 'pointer' }}>
                                <Ionicons name="md-call" size={18} color="#A80032" />
                              </div>
                              {show_marker_data.delivery_person_full_data?.user_details?.email && (
                                <div className="call-btn" onClick={(e) => handleEmail(e, show_marker_data.delivery_person_full_data?.user_details.email)} style={{ cursor: 'pointer' }}>
                                  <Ionicons name="md-mail" size={18} color="#A80032" />
                                </div>
                              )}
                            </div>
                          </div></div>



  <div className="pt-3">
  <p>User Id: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.user_id}</span></p>
  <p>Email: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.email}</span></p>
  <p>Mobile: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.full_mobile}</span></p>
  



                          {/* <div>
                            <div className="pb-3 d-flex">
                              {ord_ele.hasOwnProperty("profile_picture") && ord_ele.profile_picture != null ? (
                                <div className="pe-2">
                                  <img
                                    src={ord_ele.profile_picture}
                                    alt="Profile"
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                    style={{ borderRadius: "50px" }}
                                  />
                                </div>
                              ) : (
                                <div className="pe-2">
                                  <img
                                    src={require("../../assets/icon/avatar.png")}
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                  />
                                </div>
                              )}
                              <div>
                                <p className="text-dark fw-bold fs-7">{ord_ele?.name}</p>
                                <p>{ord_ele?.user_name}</p>
                              </div>
                            </div>
                      
                            <div>
                              Demo Order User: <span className="fw-500">{ord_ele.demo_order ? "Yes" : "No"}</span>
                            </div>
                            <div>
                              COD: <span className="fw-500">{ord_ele.cod_allowed === "force_allowed" ? "force allowed" : ord_ele.cod_allowed}</span>
                            </div>
                            <div>
                              Mobile: <span className="fw-500">{ord_ele.full_mobile}</span>
                            </div>
                            <div>
                              Blood Group: <span className="fw-500">{ord_ele.blood_group}</span>
                            </div>
                            <div>
                              Gender: <span className="fw-500">{ord_ele.gender}</span>
                            </div>
                            <div>
                              Date of Birth: <span className="fw-500">{ord_ele.dob}</span>
                            </div>
                            <div>
                              Address: <span className="fw-500">{ord_ele.address}</span>
                            </div>
                            <div>
  Height: <span className="fw-500">{ord_ele.height}</span>
</div>
<div>
  Weight: <span className="fw-500">{ord_ele.weight}</span>
</div>
<div>
  Pincode: <span className="fw-500">{ord_ele.pincode}</span>
</div>
<div>
  Referred User ID: <span className="fw-500">{ord_ele.referred_user_id}</span>
</div>
<div>
  Default App: <span className="fw-500">{ord_ele.default_app}</span>
</div>
<div>
  Signup Platform: <span className="fw-500">{ord_ele.signup_platform}</span>
</div>
<div>
  User Status: <span className="fw-500">{ord_ele.user_status}</span>
</div>
<div>
  Profile Verified: <span className="fw-500">{ord_ele.profile_verified ? 'Yes' : 'No'}</span>
</div>
<div>
  Demo Order: <span className="fw-500">{ord_ele.demo_order ? 'Yes' : 'No'}</span>
</div>
<div>
  COD Allowed: <span className="fw-500">{ord_ele.cod_allowed}</span>
</div>

                          </div>
                      
                          <div className="ms-auto text-end">
                            <div className="mb-1">{ord_ele?.created_date}</div>
                            <div>
                              ID: <span className="fw-500">{ord_ele?.id}</span>
                            </div>
                            <div>{ord_ele?.user_status}</div>
                      
                            <div className="d-flex end">
                              <div className="call-btn m-2" onClick={(e) => handleCall(e, ord_ele?.full_mobile)} style={{ cursor: 'pointer' }}>
                                <Ionicons name="md-call" size={18} color="#A80032" />
                              </div>
                              {ord_ele?.email && (
                                <div className="call-btn" onClick={(e) => handleEmail(e, ord_ele?.email)} style={{ cursor: 'pointer' }}>
                                  <Ionicons name="md-mail" size={18} color="#A80032" />
                                </div>
                              )}
                            </div>
                          </div> */}
                          
                        </div>
                        <button className="btn btn-primary ms-auto"
            onClick={() =>
              SetUserModal((prevValue: any) => ({
                ...prevValue,
                is_open: true,
                id: show_marker_data.delivery_person_full_data?.user_details?.user_id
              }))}>View User Details</button>
                        {/* <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <img
                              src={
                                show_marker_data.delivery_person_full_data
                                  .user_details.profile
                              }
                              className="profile-round-img"
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <p>Agent ID: 1</p>
                            <p className="text-dark pt-2">
                              @
                              {
                                show_marker_data.delivery_person_full_data
                                  .user_details.username
                              }
                            </p>
                          </div>
                        </div>
                        <div className="pt-3">
                          <p>Zone Service</p>
                          <p className="text-dark pt-2">Erode</p>
                        </div>

                        <div className="pt-3">
                          <p>Current Location</p>
                          <p className="text-dark pt-2">
                            Erode Railway Station
                          </p>
                        </div>

                        <div className="pt-3">
                          <p>Customer Rating</p>
                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="#eee"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <p>Complaints</p>
                          <p className="text-dark pt-2">No Complaints</p>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
               {selected_tab.name == "Services" ? (
                <div>
                  {show_marker_data.loading ? (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                        <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <Skeleton
                              height={70}
                              width={70}
                              borderRadius={50}
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <Skeleton height={8} width={50} />
                            <div className="pt-2">
                              <Skeleton height={10} width={85} />
                            </div>
                          </div>
                        </div>
                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={50} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />

                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                      <div className="border-bottom pb-2 mb-2">

                      {console.log(show_marker_data.delivery_person_full_data.user_details.user_id)}
                      {/* {console.log(all_data)} */}
                      {all_data.zones.map((ele, i) => (
  ele.services.map((ser, j) => {
    const keyToCheck = `${ser.service_id}_${ele.id}`;
    const keyExists = show_marker_data.delivery_person_data.zone_service[keyToCheck];

    // Log the key and its existence in `zone_service`
    const matchingPartner = all_data.delivery_partners.find(
      (partner) =>
        partner.agent_id === show_marker_data.delivery_person_full_data.id
    );
    console.log(show_marker_data.delivery_person_full_data
    );
    console.log(matchingPartner
    );
    const [partner, setPartner] = useState(matchingPartner);
console.log(partner)
    const [isEnabled, setIsEnabled] = useState(partner.work_any_time[keyToCheck] );
    console.log(all_data)
    const handleSwitchChange = async () => {
  
    setIsEnabled((prev) => {
      const newState = !prev;
      // Call the async function to update work time
      get_work_time(newState);
      return newState;
    });
  }

    async function get_work_time(newState) {
  
      let pass_data = {
        post: {
          user_id:show_marker_data.delivery_person_full_data.user_details.user_id,
          zone_id:ele.id,
          service_id: ser.service_id,
          status: !newState ? "0" : "1",
        },
      };
      let response: any = await api(
        "/orders/update_delivery_partners_work_anytime",
        pass_data,
      );
      if (response.status_code == 200) {
        // setIsEnabled(response.response.status)
        // setPartner(partner.work_any_time[keyToCheck])
        const newWorkTime = { ...partner.work_any_time, keyToCheck: response.response.status };

        // Update the partner state with the new work_any_time
        setPartner((prevPartner) => ({
          ...prevPartner,
          work_any_time: newWorkTime,
        }));

        SetAllData((prevValue: any) => {
          const updatedPartners = prevValue?.delivery_partners?.map((ele: any) => {
            if (ele.agent_id === partner.agent_id) {
              // Modify the specific delivery partner here
              return {
                ...ele,
                work_any_time: {
                  ...ele.work_any_time,
                  [keyToCheck]: newState, // Toggle the value dynamically
                },
              };
            }
            return ele; // Keep other partners unchanged
          });
        
          return {
            ...prevValue,
            delivery_partners: updatedPartners,
          };
        });
      }
    }
    return (
      keyExists &&
      (<div key={`${i}-${j}`}>
        <div className = "d-flex">
          <div>
        <div>{ser.service_name}</div>
        <div>{ele.name}</div>
        </div>
        <div class="d-flex align-items-center ms-auto">
            <div
              class="">Work Any Time</div>
            <div class="form-check form-switch ms-2">
              <input class="form-check-input" type="checkbox" id="demoOrderSwitch1"
                checked={isEnabled}
                onChange={handleSwitchChange} />
              <label class="form-check-label" for="demoOrderSwitch1"></label>
            </div>
          </div>
        </div>

   
        <br />
      </div>
      )
    );
  })
))}
   
      </div>




                        {/* <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <img
                              src={
                                show_marker_data.delivery_person_full_data
                                  .user_details.profile
                              }
                              className="profile-round-img"
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <p>Agent ID: 1</p>
                            <p className="text-dark pt-2">
                              @
                              {
                                show_marker_data.delivery_person_full_data
                                  .user_details.username
                              }
                            </p>
                          </div>
                        </div>
                        <div className="pt-3">
                          <p>Zone Service</p>
                          <p className="text-dark pt-2">Erode</p>
                        </div>

                        <div className="pt-3">
                          <p>Current Location</p>
                          <p className="text-dark pt-2">
                            Erode Railway Station
                          </p>
                        </div>

                        <div className="pt-3">
                          <p>Customer Rating</p>
                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="#eee"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <p>Complaints</p>
                          <p className="text-dark pt-2">No Complaints</p>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </>
        )}
      </>
    );
  };

  function get_statistics_name(value: any) {
    let return_value: any;
    let value1 = value.replaceAll("_", " ");
    return_value = value1.charAt(0).toUpperCase() + value1.slice(1);
    return return_value;
  }

  return (
    <div className="p-3">
      <div className="row py-0 px-3 sm-p-0">
        <div className="col-md-7 p-2 card map-height sm-p-0 sm-mt-5">
          <div id="map-test" className="map_class  p-1"></div>
        </div>
        <div className="col-md-5 md-block d-sm-none map_side pe-0 ">
          {/* go back header */}
          {show_marker_data.view_type != "main" &&
          show_marker_data.view_type != null ? (
            <div className="ps-md-2 mb-2">
              <div className="card p-3 mt-2 bg-fff  ">
                <div
                  className="cursor d-flex align-items-center"
                  onClick={() => {
                    // window.history.go(-1);
                    SetShowMarkerData((preValue: any) => {
                      preValue.view_type = "main";
                      return { ...preValue };
                    });
                    Object.keys(marker).map((key) => {
                      let doc_var: any = document.querySelector(
                        'img[src="' + marker[key].pass_data.added_url + '"]'
                      );
                      if (doc_var != null) {
                        doc_var.style.transform =
                          "rotate(" +
                          marker[key].pass_data.delivery_person_data.location
                            .heading +
                          "deg) scale(1)";
                        doc_var.parentElement.style.overflow = "inherit";
                        // doc_var.style.transform = "scale(1)";
                      }
                    });
                  }}
                >
                  <Ionicons name="arrow-back-sharp" size={20} color="black" />
                  <p className="text-dark fs-7 ps-2 fw-bold">
                    Go to Statistics
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="ps-md-2">
            <div className="">
              {show_map_details_loader ? (
                <>
                  <div className="card p-3 mb-2">
                    <div className="mb-4 mt-2">
                      <Skeleton height={10} width={100} />
                    </div>
                    <div className="row pt-2">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                        <div className="col-4 d-flex pb-4">
                          <Skeleton height={20} width={20} />
                          <div className="ps-2 pb-2">
                            <Skeleton height={10} width={100} />
                            <div className="pt-2">
                              <Skeleton height={10} width={30} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card p-3 mt-3">
                    <div className="row">
                      <div className="mb-4 mt-2">
                        <Skeleton height={10} width={80} />
                      </div>
                      <div className="mb-3">
                        <Skeleton height={30} width="100%" />
                      </div>
                      <div className="p-3">
                        <Skeleton
                          height={10}
                          width={80}
                          count={5}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : show_marker_data.view_type == "main" ? (
                <>
                  <div className="card p-3  scrollY70">
                    <h6 className="mb-4 fw-bold mt-2">Statistics</h6>
                    {/* <div className=""> */}
                    <div className="row pt-2">
                      {Object.keys(all_data?.statistics)?.length > 0
                        ? Object.keys(all_data?.statistics)?.map((key) => (
                            <div className="col-4 d-flex pb-4">
                              {key == "total_agents" ? (
                                <img
                                  src={require("../../assets/icon/Delivery.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_now" ? (
                                <img
                                  src={require("../../assets/icon/user.png")}
                                  className="icon-w20"
                                />
                              ) : key ==
                                "total_agents_active_now_non_blocked" ? (
                                <img
                                  src={require("../../assets/icon/confirm.png")}
                                  className="icon-w20"
                                />
                              ) : key ==
                                "total_agents_active_now_blocked_new" ? (
                                <img
                                  src={require("../../assets/icon/block-user.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_today" ? (
                                <img
                                  src={require("../../assets/icon/user-profile.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_free" ? (
                                <img
                                  src={require("../../assets/icon/employee.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_busy" ? (
                                <img
                                  src={require("../../assets/icon/busy.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_free_reserved" ? (
                                <img
                                  src={require("../../assets/icon/reserve.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_busy_reserved" ? (
                                <img
                                  src={require("../../assets/icon/delivery-man.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_30d" ? (
                                <img
                                  src={require("../../assets/icon/30day.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_15d" ? (
                                <img
                                  src={require("../../assets/icon/15day.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_1w" ? (
                                <img
                                  src={require("../../assets/icon/1week.png")}
                                  className="icon-w20"
                                />
                              ) : (
                                <img
                                  src={require("../../assets/icon/Delivery.png")}
                                  className="icon-w20"
                                />
                              )}

                              <div className="ps-2 pb-2">
                                <p className="h-35 ">
                                  {get_statistics_name(key)}
                                </p>
                                <p className="text-dark fw-bold mt-2">
                                  {all_data.statistics[key]}
                                </p>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="card p-3 mt-3 scrollY70 ">
                    <div className="row">
                      <h6 className="mb-4 fw-bold mt-2">Delivery Person</h6>
                      <div className=" mb-3">
                        
                        <input
                          className="form-control radius w-100 p-2"
                          type="text"
                          name=""
                          id=""
                          placeholder="Search Delivery Person"
                          onChange={(e) => {
                            console.log(e.target.value)
                            let enter_value = e.target.value;
                            
                            clearTimeout(timeOutId); // Clear any existing timeout
                            if (enter_value == "") {
                              SetDPFilterData(all_data.delivery_partners);
                            } else {
                              timeOutId = setTimeout(() => {

                                let old_data = whole_dp_fiter_data.filter(

                                  (value: any) => {
                                    const searchStr =
                                      enter_value.toLowerCase();
                                    const nameMatches = value.name
                                      .toLowerCase()
                                      .includes(searchStr);

                                    return nameMatches;
                                  }
                                )

                                SetDPFilterData(old_data);


                                // SetDPFilterData((prevalue: any) => {
                                //   console.log(prevalue)
                                //   const filteredData = prevalue.filter(
                                //     (value: any) => {
                                //       const searchStr =
                                //         enter_value.toLowerCase();
                                //       const nameMatches = value.name
                                //         .toLowerCase()
                                //         .includes(searchStr);

                                //       return nameMatches;
                                //     }
                                //   );
                                //   // prevalue = filteredData;
                                //   // return [...prevalue];

                                //  return filteredData
                                // });

                                // SetDPFilterData(all_data.delivery_partners)
                              }, 500);
                              return () => clearTimeout(timeOutId);
                            }
                          }}
                        />
                      </div>
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            ele.hasOwnProperty("logged_in") &&
                            ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    
                                    onClick={() => {
                                      console.log("hi")
                                   
                                          Object.keys(marker).map((key) => {
                                            if (key == ele.agent_id) {
                                              let doc_var: any = document.querySelector(
                                                'img[src="' + marker[key].pass_data.added_url + '"]'
                                              );
                                              if (doc_var != null) {
                                                doc_var.style.transform =
                                                  "rotate(" +
                                                  ele.location.heading +
                                                  "deg) scale(2)";
                                                doc_var.parentElement.style.overflow = "inherit";
                                                // doc_var.style.transform = "scale(2)";
                                                doc_var.style.transition = "scale 300ms";
                                              }
                                            } else {
                                              let doc_var: any = document.querySelector(
                                                'img[src="' + marker[key].pass_data.added_url + '"]'
                                              );
                                              if (doc_var != null) {
                                                doc_var.style.transform =
                                                  "rotate(" +
                                                  marker[key].pass_data.delivery_person_data.location.heading +
                                                  "deg) scale(1)";
                                                doc_var.parentElement.style.overflow = "inherit";
                                              }
                                            }
                                          });
                                    
                                    



                                      SetShowMarkerData((prevValue) => {
                                        return {
                                          ...prevValue,
                                          is_open: true,
                                          view_type: "personal_details",
                                          loading: true,
                                        };
                                      });

                                      let send_data = {
                                        delivery_person_data: ele,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data);
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                                <div className="pb-3 ms-auto">
                                  {ele.active_orders == 0 && (
                                    <button
                                      className="btn-prime btn btn-sm px-3 py-1"
                                      onClick={() =>
                                        SetModalVariants((prevValue) => ({
                                          ...prevValue,
                                          is_open: true,
                                          data: {
                                            company_id: all_data.id,
                                            agent_id: ele.agent_id,
                                            all_data: all_data,
                                          },
                                        }))
                                      }
                                    >
                                      Stop
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {dp_fiter_data.some(
                        (ele: any) =>
                          !ele.hasOwnProperty("logged_in") && !ele.logged_in
                      ) && <h6>Offline</h6>}
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            !ele.hasOwnProperty("logged_in") &&
                            !ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    onClick={() => {
                                      SetShowMarkerData((prevValue) => {
                                        return {
                                          ...prevValue,
                                          is_open: true,
                                          view_type: "personal_details",
                                          loading: true,
                                        };
                                      });

                                      let send_data = {
                                        delivery_person_data: ele,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data);
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            {show_marker_data.view_type == "personal_details" ? (
              <PersonDetailsPanel />
            ) : null}
          </div>
        </div>
      </div>
      <ModalStop
        open={modal_variants.is_open}
        data={modal_variants.data}
        close={(data: any) => {
          SetModalVariants((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};

            return { ...prevValue };
          });

          if (data.action == "save") {
            data.all_data.delivery_partners.map(
              (ele: any) =>
                ele.hasOwnProperty("logged_in") &&
                ele.logged_in &&
                marker[ele.agent_id]["marker"].setMap(null)
            );

            get_map_details();
          }
        }}
      />

      {width < 768 ? (
        <SwipeableBottomSheet
          overflowHeight={140}
          style={{ zIndex: 1300 }}
          open={bottom_sheet_open}
          onClose={() => {
            SetBottomSheetOpen(false);
          }}
          onChange={() => {
            SetBottomSheetOpen((prevalue) => {
              if (prevalue) {
                prevalue = false;
              } else {
                prevalue = true;
              }
              return prevalue;
            });
          }}
        >
          <div className="map-swipe-sheet pb-3">
            <div className="bg-fff border-top-radius p-3 pb-1 center w-100">
              <div className="bottom-sheet-hr "></div>
            </div>
            {show_marker_data.view_type == "main" ? (
              <div className="">
                <div className="bg-fff p-2 pb-0 position-sticky-top">
                  <Toaster
                    toastOptions={{
                      className: "",
                      style: {
                        zIndex: 1400,
                      },
                      duration: 150000,
                    }}
                  />
                  <AliceCarousel
                    mouseTracking
                    touchMoveDefaultEvents={false}
                    // paddingRight={10}
                    disableDotsControls
                    responsive={responsive}
                    disableButtonsControls
                    controlsStrategy="alternate"
                  >
                    {main_tab_data?.map((main_tab: any, index: any) => (
                      <div
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        key={index}
                      >
                        <div
                          className="text-center"
                          onClick={() => {
                            SetSelectedMainTab(main_tab);
                          }}
                        >
                          <div
                            className={
                              selected_main_tab.id == main_tab.id
                                ? "tab-active p-2 pt-3 cursor sm-px-0"
                                : "cursor p-2 pt-3 sm-px-0 border-bottom"
                            }
                          >
                            <div>
                              <p
                                className={
                                  selected_main_tab.id == main_tab.id
                                    ? "py-1 text-prime"
                                    : "py-1 text-black"
                                }
                              >
                                {main_tab.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AliceCarousel>
                </div>
                <div className="p-2 pt-3 pb-5 ">
                  {selected_main_tab.name == "Statistics" ? (
                    <div className="card px-2 pt-3 scrollY70">
                      <p className="text-dark fs-7 fw-bold">Statistics</p>
                      {/* <div className=""> */}
                      <div className="row pt-4">
                        {Object.keys(all_data.statistics).length > 0
                          ? Object.keys(all_data.statistics).map((key) => (
                              <div className="col-4  d-flex pb-4">
                                {key == "total_agents" ? (
                                  <img
                                    src={require("../../assets/icon/Delivery.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_active_now" ? (
                                  <img
                                    src={require("../../assets/icon/user.png")}
                                    className="icon-14px"
                                  />
                                ) : key ==
                                  "total_agents_active_now_non_blocked" ? (
                                  <img
                                    src={require("../../assets/icon/confirm.png")}
                                    className="icon-14px"
                                  />
                                ) : key ==
                                  "total_agents_active_now_blocked_new" ? (
                                  <img
                                    src={require("../../assets/icon/block-user.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_active_today" ? (
                                  <img
                                    src={require("../../assets/icon/user-profile.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_free" ? (
                                  <img
                                    src={require("../../assets/icon/employee.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_busy" ? (
                                  <img
                                    src={require("../../assets/icon/busy.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_free_reserved" ? (
                                  <img
                                    src={require("../../assets/icon/reserve.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_busy_reserved" ? (
                                  <img
                                    src={require("../../assets/icon/delivery-man.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_active_30d" ? (
                                  <img
                                    src={require("../../assets/icon/30day.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_active_15d" ? (
                                  <img
                                    src={require("../../assets/icon/15day.png")}
                                    className="icon-14px"
                                  />
                                ) : key == "total_agents_active_1w" ? (
                                  <img
                                    src={require("../../assets/icon/1week.png")}
                                    className="icon-14px"
                                  />
                                ) : (
                                  <img
                                    src={require("../../assets/icon/Delivery.png")}
                                    className="icon-14px"
                                  />
                                )}

                                <div className="ps-2 pb-2">
                                  <div className="h-35 text-gray sm-fs-10">
                                    {get_statistics_name(key)}
                                  </div>
                                  <p className="text-dark fw-bold mt-2">
                                    {all_data.statistics[key]}
                                  </p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  ) : null}

                  {selected_main_tab.name == "Delivery Person" ? (
                    <div className="card px-2 pt-3 scrollY70">
                      <p className="text-dark fs-7 fw-bold">Delivery Person</p>

                      <div className=" pt-3">
                        <input
                          className="form-control radius w-100 p-2"
                          type="text"
                          name=""
                          id=""
                          placeholder="Search Delivery Person"
                          onChange={(e) => {
                            let enter_value = e.target.value;
                            if (enter_value == "") {
                              SetDPFilterData(all_data.delivery_partners);
                            } else {
                              const timeOutId = setTimeout(() => {
                                let old_data = whole_dp_fiter_data.filter(

                                  (value: any) => {
                                    const searchStr =
                                      enter_value.toLowerCase();
                                    const nameMatches = value.name
                                      .toLowerCase()
                                      .includes(searchStr);

                                    return nameMatches;
                                  }
                                )

                                SetDPFilterData(old_data);
                              }, 500);
                              return () => clearTimeout(timeOutId);
                            }
                          }}
                        />
                      </div>
                      <div className="px-1 py-3">
                        {dp_fiter_data.map((ele: any) =>
                          ele.hasOwnProperty("logged_in") && ele.logged_in && (
                            <div className="d-flex align-items-center">
                              <div className="pb-3">
                                <p
                                  className="text-black"
                                  onClick={() => {
                                    SetShowMarkerData((prevValue) => {
                                      return {
                                        ...prevValue,
                                        is_open: true,
                                        view_type: "personal_details",
                                        loading: true,
                                      };
                                    });

                                    let send_data = {
                                      delivery_person_data: ele,
                                    };

                                    let send_data1 = {
                                      company_data: all_data,
                                      delivery_person_data: ele,
                                      page_no: page_no,
                                      fetch_more: false,
                                    };
                                    get_person_details(send_data);
                                    get_person_sessions(send_data1);
                                  }}
                                >
                                  {ele.name}
                                </p>
                              </div>
                              <div className="pb-3 ms-auto">
                                {ele.active_orders == 0 && (
                                  <button
                                    className="btn-prime btn btn-sm px-3 py-1"
                                    onClick={() =>
                                      SetModalVariants((prevValue) => ({
                                        ...prevValue,
                                        is_open: true,
                                        data: {
                                          company_id: all_data.id,
                                          agent_id: ele.agent_id,
                                          all_data: all_data,
                                        },
                                      }))
                                    }
                                  >
                                    Stop
                                  </button>
                                )}
                              </div>
                            </div>
                          ) 
                        )}
                      </div>

                      {dp_fiter_data.some(
                        (ele: any) =>
                          !ele.hasOwnProperty("logged_in") && !ele.logged_in
                      ) && <h6>Offline</h6>}
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            !ele.hasOwnProperty("logged_in") &&
                            !ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    onClick={() => {
                                      let send_data = {
                                        delivery_person_data: ele,
                                      };

                                      let send_data1 = {
                                        company_data: all_data,
                                        delivery_person_data: ele,
                                        page_no: page_no,
                                        fetch_more: false,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data1);
                                    
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                className="cursor d-flex align-items-center bg-fff px-3 pt-2"
                onClick={() => {
                  SetShowMarkerData((preValue: any) => {
                    preValue.view_type = "main";
                    return { ...preValue };
                  });
                  Object.keys(marker).map((key) => {
                    let doc_var: any = document.querySelector(
                      'img[src="' + marker[key].pass_data.added_url + '"]'
                    );
                    if (doc_var != null) {
                      doc_var.style.transform =
                        "rotate(" +
                        marker[key].pass_data.delivery_person_data.location
                          .heading +
                        "deg) scale(1)";
                      doc_var.parentElement.style.overflow = "inherit";
                    }
                  });
                }}
              >
                <Ionicons name="arrow-back-sharp" size={20} color="black" />
                <p className="text-dark fs-7 ps-2 fw-bold">Go to Statistics</p>
              </div>
            )}
            {show_marker_data.view_type == "personal_details" ? (
              <PersonDetailsPanel />
            ) : null}
          </div>
          {/* <ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}/> */}
        </SwipeableBottomSheet>
      ) : null}
      


      <ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}/>
    </div>
  );
}