import React, { useState, useEffect } from "react";

import { api } from "../../utils/Api";
import { FontAwesome } from "@expo/vector-icons";

import ModalAcceptRequest from "./ModalAcceptRequest";
import ModalRejectRequest from "./ModalRejectRequest";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ProductUpdateRequest() {
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [openRejModal, SetopenRejModal] = useState({
    is_open: false,
    data: {},
  });

  const [type, setType] = useState("all");

  const [product_data, SetProductData] = useState([]);

  const [next_page, SetNextPage] = useState(false);

  const [page_no, SetPageNo] = useState(1);

  const [all, SetAll] = useState(true);
  const [pending, SetPending] = useState(false);
  const [process, SetProcess] = useState(false);

  const [visible_content, SetVisibleContent] = useState(false);

  const [order_status, SetOrderStatus] = useState(1);

  useEffect(() => {
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: page_no,
    };

    get_products(pass_data, type);
  }, []);

  async function get_products(page_value: any, type_a: any) {
    if (!page_value.load_more) {
      SetVisibleContent(false);
    }

    let pass_data = {};

    pass_data = {
      get: {
        page: page_value?.page_no,

        status: type_a,
      },
    };

    let response: any = await api(
      "/products/product_update_request",
      pass_data
    );
    if (response.status_code == 200) {
      setType(type_a);

      if (response.response.hasOwnProperty("data")) {
        SetVisibleContent(true);
        if (page_value.load_more) {
          SetProductData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetProductData(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }

   async function AcceptRequest(id:any) {
      let pass_data = {
        post: {
          id: id,
          status: "1",
        },
      };
       let data_res;
     
       data_res = await api("/products/product_update_request_status", pass_data);
     
      if (data_res.status_code == 200) {
        // let item = close_data;
        // item.action = "save";
        // item.value = data_res.response.data;
        let pass_data = {
          order_status: 1,
          seller_brand_id: "",
          seller_outlet_id: "",
          sort: "",
          page_no: page_no,
        };
    
        get_products(pass_data, type);
     
      }
    }

  return (
    <div className="">
      <div className="head-bar">
        <div className="sm-mb-2">
          <h5 className="  mb-1">Product Update Request</h5>
        </div>

        <div className="mt-2 d-flex align-items-center bg-fff py-2 scrollX">
          <button
            type="button"
            className={
              all
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => {
              SetAll(!all);
              SetPending(false);
              SetProcess(false);
              SetOrderStatus(1);
              SetPageNo(1);
              let pass_data = {
                order_status: 1,
                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                page_no: 1,
              };
              // get_products(pass_data, filter_data);
              get_products(pass_data, 1);
            }}
          >
            New
          </button>

          <button
            type="button"
            className={
              pending
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => {
              SetAll(false);
              SetPending(!pending);
              SetProcess(false);
              SetOrderStatus(2);
              SetPageNo(1);
              let pass_data = {
                order_status: 2,
                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                page_no: 1,
              };
              // get_products(pass_data, filter_data);
              get_products(pass_data, 2);
            }}
          >
            Accepted
          </button>

          <button
            type="button"
            className={
              process
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => {
              SetAll(false);
              SetProcess(!process);

              SetPending(false);

              SetOrderStatus(3);
              SetPageNo(1);
              let pass_data = {
                order_status: 3,
                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                page_no: 1,
              };
              // get_products(pass_data, filter_data);
              get_products(pass_data, 3);
            }}
          >
            Rejected
          </button>
        </div>
      </div>

      {/* Body */}

      <div className="px-2 sm-mt-2 sm-pt-4 mb-5">
        <div className="mt-4">
          <InfiniteScroll
            dataLength={product_data.length}
            next={() => {
             
              let pass_data = {
                order_status: order_status,
                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                load_more: true,

                page_no: page_no,
              };

              get_products(pass_data, type);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            {" "}
            {visible_content ? (
              product_data.length != 0 ? (
                <div className="row">
                  {product_data?.map((ord_ele: any, ord_index: any) => (
                    <>
                      <div className="col-md-6 col-12 mb-3" key={ord_index}>
                        <div className="card p-3 cursor">
                          {/* header */}
                          <div className="row">
                            <div className="col-6 mb-2">
                              <p className="fw-bold text-dark">#{ord_ele.id}</p>
                            </div>
                            <div className="col-6 mb-2 text-end">
                              <p className="small text-gray">{ord_ele.date}</p>
                            </div>
                            <div className="col-6 mb-2">
                              <p className="text-black small">
                                Product Id: {ord_ele.product_id}
                              </p>
                            </div>
                            <div className="col-6 mb-2 end">
                              <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  ord_ele.status == "Accepted"
                                    ? "blue"
                                    : ord_ele.status == "Completed"
                                    ? "green"
                                    : ord_ele.status == "Order Placed"
                                    ? "orange"
                                    : "red"
                                }
                              />
                              <p
                                className={
                                  ord_ele.status == "Accepted"
                                    ? "text-blue ps-2"
                                    : ord_ele.status == "Completed"
                                    ? "text-green ps-2"
                                    : ord_ele.status == "Order Placed"
                                    ? "text-orange ps-2"
                                    : "text-red ps-2"
                                }
                              >
                                {ord_ele.status}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex pb-2">
                            <img
                              src={require("../../assets/icon/store.png")}
                              className="icon-14px "
                            />
                            <div className="ps-2">
                              <p className="small">Seller Name</p>
                              <p className="text-dark">
                                {ord_ele.seller.brand_name}
                              </p>
                            </div>
                          </div>

                          <div className=" mt-1">
                            <div className="d-flex mb-3">
                              <img
                                src={require("../../assets/icon/cart.png")}
                                className="icon-14px "
                              />

                              <div className="ps-2">
                                <p className="extra-small text-gray">
                                  Product Name
                                </p>
                                {ord_ele.product_name && (
                                  <p className="text-dark">
                                    {ord_ele.product_name}
                                  </p>
                                )}
                              </div>
                            </div>

                            {ord_ele.product_description && (
                              <div className="d-flex">
                                <img
                                  src={require("../../assets/icon/notes.png")}
                                  className="icon-14px "
                                />

                                <div className="ps-2  mb-3 ">
                                  <p className="extra-small text-gray">
                                    Product Description
                                  </p>
                                  <p className="pt-1 small text-dark">
                                    {ord_ele.product_description}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Field</th>
                                <th scope="col">Old Data</th>
                                <th scope="col">New Data</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(ord_ele.product_details.category_name ||
                                ord_ele.updated_product_details
                                  .category_name) && (
                                <tr>
                                  <td>Category</td>

                                  <td>
                                    {ord_ele.product_details.category_name}
                                  </td>
                                  <td>
                                    {
                                      ord_ele.updated_product_details
                                        .category_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* HSN */}
                              {(ord_ele.product_details.hsn ||
                                ord_ele.updated_product_details.hsn) && (
                                <tr>
                                  <td>HSN</td>
                                  <td>{ord_ele.product_details.hsn}</td>
                                  <td>{ord_ele.updated_product_details.hsn}</td>
                                </tr>
                              )}

                              {/* GST */}
                              {(ord_ele.product_details.gst ||
                                ord_ele.updated_product_details.gst) && (
                                <tr>
                                  <td>GST</td>
                                  <td>{ord_ele.product_details.gst}</td>
                                  <td>{ord_ele.updated_product_details.gst}</td>
                                </tr>
                              )}

                              {/* Preparing Duration */}
                              {(ord_ele.product_details.preparing_duration ||
                                ord_ele.updated_product_details
                                  .preparing_duration) && (
                                <tr>
                                  <td>Preparing Duration</td>
                                  <td>
                                    {ord_ele.product_details.preparing_duration}
                                  </td>
                                  <td>
                                    {
                                      ord_ele.updated_product_details
                                        .preparing_duration
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* GTIN */}
                              {(ord_ele.product_details.gtin ||
                                ord_ele.updated_product_details.gtin) && (
                                <tr>
                                  <td>GTIN</td>
                                  <td>{ord_ele.product_details.gtin}</td>
                                  <td>
                                    {ord_ele.updated_product_details.gtin}
                                  </td>
                                </tr>
                              )}

                              {/* UPC */}
                              {(ord_ele.product_details.upc ||
                                ord_ele.updated_product_details.upc) && (
                                <tr>
                                  <td>UPC</td>
                                  <td>{ord_ele.product_details.upc}</td>
                                  <td>{ord_ele.updated_product_details.upc}</td>
                                </tr>
                              )}

                              {/* EAN */}
                              {(ord_ele.product_details.ean ||
                                ord_ele.updated_product_details.ean) && (
                                <tr>
                                  <td>EAN</td>
                                  <td>{ord_ele.product_details.ean}</td>
                                  <td>{ord_ele.updated_product_details.ean}</td>
                                </tr>
                              )}

                              {/* JAN */}
                              {(ord_ele.product_details.jan ||
                                ord_ele.updated_product_details.jan) && (
                                <tr>
                                  <td>JAN</td>
                                  <td>{ord_ele.product_details.jan}</td>
                                  <td>{ord_ele.updated_product_details.jan}</td>
                                </tr>
                              )}

                              {/* ISBN */}
                              {(ord_ele.product_details.isbn ||
                                ord_ele.updated_product_details.isbn) && (
                                <tr>
                                  <td>ISBN</td>
                                  <td>{ord_ele.product_details.isbn}</td>
                                  <td>
                                    {ord_ele.updated_product_details.isbn}
                                  </td>
                                </tr>
                              )}

                              {/* MPN */}
                              {(ord_ele.product_details.mpn ||
                                ord_ele.updated_product_details.mpn) && (
                                <tr>
                                  <td>MPN</td>
                                  <td>{ord_ele.product_details.mpn}</td>
                                  <td>{ord_ele.updated_product_details.mpn}</td>
                                </tr>
                              )}

                              {/* Min Handling Time */}
                              {(ord_ele.product_details.min_handling_time ||
                                ord_ele.updated_product_details
                                  .min_handling_time) && (
                                <tr>
                                  <td>Min Handling Time</td>
                                  <td>
                                    {ord_ele.product_details.min_handling_time}
                                  </td>
                                  <td>
                                    {
                                      ord_ele.updated_product_details
                                        .min_handling_time
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Max Handling Time */}
                              {(ord_ele.product_details.max_handling_time ||
                                ord_ele.updated_product_details
                                  .max_handling_time) && (
                                <tr>
                                  <td>Max Handling Time</td>
                                  <td>
                                    {ord_ele.product_details.max_handling_time}
                                  </td>
                                  <td>
                                    {
                                      ord_ele.updated_product_details
                                        .max_handling_time
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Height */}
                              {(ord_ele.product_details.height ||
                                ord_ele.updated_product_details.height ||
                                ord_ele.product_details.height_unit_name ||
                                ord_ele.updated_product_details
                                  .height_unit_name) && (
                                <tr>
                                  <td>Height</td>
                                  <td>
                                    {ord_ele.product_details.height}{" "}
                                    {ord_ele.product_details.height_unit_name}
                                  </td>
                                  <td>
                                    {ord_ele.updated_product_details.height}{" "}
                                    {
                                      ord_ele.updated_product_details
                                        .height_unit_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Width */}
                              {(ord_ele.product_details.width ||
                                ord_ele.updated_product_details.width) && (
                                <tr>
                                  <td>Width</td>
                                  <td>
                                    {ord_ele.product_details.width}{" "}
                                    {ord_ele.product_details.width_unit_name}
                                  </td>
                                  <td>
                                    {ord_ele.updated_product_details.width}{" "}
                                    {
                                      ord_ele.updated_product_details
                                        .width_unit_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Length */}
                              {(ord_ele.product_details.length ||
                                ord_ele.updated_product_details.length) && (
                                <tr>
                                  <td>Length</td>
                                  <td>
                                    {ord_ele.product_details.length}{" "}
                                    {ord_ele.product_details.length_unit_name}
                                  </td>
                                  <td>
                                    {ord_ele.updated_product_details.length}{" "}
                                    {
                                      ord_ele.updated_product_details
                                        .length_unit_name
                                    }
                                  </td>
                                </tr>
                              )}

                              {/* Weight */}
                              {(ord_ele.product_details.weight ||
                                ord_ele.updated_product_details.weight) && (
                                <tr>
                                  <td>Weight</td>
                                  <td>
                                    {ord_ele.product_details.weight}{" "}
                                    {ord_ele.product_details.weight_unit_name}
                                  </td>
                                  <td>
                                    {ord_ele.updated_product_details.weight}{" "}
                                    {
                                      ord_ele.updated_product_details
                                        .weight_unit_name
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {ord_ele.status == "New" && (
                            <div className="">
                              <div className="row align-items-center py-3">
                                <div className="col-6 ">
                                  <button
                                    className="btn btn-sm btn-outline-secondary px-4"
                                    onClick={() => {
                                      SetopenRejModal({
                                        is_open: true,
                                        data: {
                                          id: ord_ele.id,
                                        },
                                      });
                                    }}
                                  >
                                    REJECT
                                  </button>
                                </div>
                                <div className="col-6 text-end">
                                  <button
                                    className="btn btn-sm btn-blue text-white px-4"
                                    onClick={() => {
                                      // SetopenModal({
                                      //   is_open: true,
                                      //   data: {
                                      //     id: ord_ele.id,
                                      //   },
                                      // });

                                      AcceptRequest(ord_ele.id)
                                    }}
                                  >
                                    ACCEPT
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <div className="center p-3">
                  <div className="card p-3 w-100">
                    <div className="w-100 text-center">
                      <img
                        src={require("../../assets/img/not-found.png")}
                        className="no-data-img"
                      />
                    </div>
                    <p className="center pt-3">
                      No Product Update Request data
                    </p>
                  </div>
                </div>
              )
            ) : (
              <div className="row">
                {[1, 2, 3, 4, 5, 6, 7].map(() => (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="card p-3 cursor">
                      {/* header */}
                      <div className="row">
                        <div className="col-6 mb-2">
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 mb-2 text-end">
                          <Skeleton height={10} width={80} />
                        </div>
                        <div className="col-6 mb-2">
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 mb-2 end">
                          <Skeleton height={10} width={90} />
                        </div>
                      </div>

                      <div className="d-flex pb-2">
                        <Skeleton height={14} width={14} />
                        <div className="ps-2">
                          <Skeleton height={10} width={40} />
                          <Skeleton height={10} width={80} />
                        </div>
                      </div>

                      <div className="mb-3 mt-1">
                        <div className="d-flex pb-2">
                          <Skeleton height={14} width={14} />
                          <div className="ps-2">
                            <Skeleton height={10} width={40} />
                            <Skeleton height={10} width={80} />
                          </div>
                        </div>

                        <div className="pt-1">
                          <Skeleton height={10} width="100%" />
                        </div>
                      </div>

                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <Skeleton height={10} width={30} />
                            </th>
                            <th scope="col">
                              <Skeleton height={10} width={80} />
                            </th>
                            <th scope="col">
                              <Skeleton height={10} width={30} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton height={10} width={30} />
                            </td>

                            <td>
                              <Skeleton height={10} width={50} />
                            </td>
                            <td>
                              <Skeleton height={10} width={100} />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <Skeleton height={10} width={30} />
                            </td>
                            <td>
                              <Skeleton height={10} width={50} />
                            </td>
                            <td>
                              <Skeleton height={10} width={90} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="">
                        <div className="row align-items-center py-3">
                          <div className="col-6">
                            <Skeleton
                              height={30}
                              width={100}
                              borderRadius={6}
                            />
                          </div>
                          <div className="col-6 text-end">
                            <Skeleton
                              height={30}
                              width={100}
                              borderRadius={6}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
      <ModalAcceptRequest
        open={openModal.is_open}
        data={openModal.data}
        close={(data: any) => {
          SetopenModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            let pass_data = {
              order_status: 3,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: 1,
            };
            get_products(pass_data, type);
          }
        }}
      />
      <ModalRejectRequest
        open={openRejModal.is_open}
        data={openRejModal.data}
        close={(data: any) => {
          SetopenRejModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            let pass_data = {
              order_status: 3,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: 1,
            };
            get_products(pass_data, type);
          }
        }}
      />
    </div>
  );
}
